function fillIt() {
    var data;
    var search = location.search.substring(1);
    console.log(search);
    if (search){
        data = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    } else {
        data = '';
    }
    console.log(data)
    D = document.forms[0];
    D['firstname'].value = data['firstname'] || 'Florian';
    D['lastname'].value = data['lastname'] ||'Coulombel';
    D['birthday'].value = data['birthday'] || '11/07/1982';
    D['lieunaissance'].value = data['lieunaissance'] || 'bethune';
    D['checkbox-courses'].checked = true;
    D['address'].value = data['address'] || '27 rue Vergniaud';
    D['town'].value = data['town'] || 'Lille';
    D['zipcode'].value = data['zipcode'] || 59000;
}
fillIt();